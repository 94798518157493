import {Link} from "gatsby";
import React from "react";

class Report extends React.Component {
	render() {
		return (
			<div className="hero layered-gradient-blue">
				<div className="grid-container grid-x align-center-middle padding-vertical">
					<div className="report-container">
						Report
					</div>
				</div>

				<div className="curved-separator"></div>
			</div>
		);
	}
}

export default Report;
