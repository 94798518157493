import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Report from "../components/report"
import {Link} from "gatsby";

const IndexPage = () => (
	<Layout>
		<SEO title="Home"/>

		{/* Homepage Hero */}
		<Report />

		<div className="grid-container background-white text-center padding-vertical-large">
			<h4 className="margin-bottom">The Next Step: Cleaning your list through <a href="https://www.atdata.com/safetosend">SafeToSend</a></h4>
			<a href="/Home/Start" target="_blank" className="button">
				Check My List
			</a>
		</div>

		<div className="background-medium-gray text-center padding-vertical">
			<div className="grid-container">
				<strong>This report was generated by&nbsp;
					<Link to="/">
						<span className="underline">
							Free List Check
						</span>
					</Link>
				</strong>
			</div>
		</div>

	</Layout>
)

export default IndexPage
